import {Component} from '@angular/core'
import {environment} from '../../../environments/environment'
import {ConfigService} from '../../services/config.service'

@Component({
  selector: 'spb-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  protected readonly canUseTestingTools = !environment.production

  constructor(public configService: ConfigService) {
  }
}
