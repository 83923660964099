import {Injectable, signal, WritableSignal} from '@angular/core'
import {Router} from '@angular/router'
import {
  HelperService,
  SingleSignOnService,
  SparbankenUser
} from '@sparbanken-syd/sparbanken-syd-bankid'
import {
  BehaviorSubject,
  catchError,
  EMPTY,
  Observable,
  of,
  switchMap
} from 'rxjs'
import {environment} from '../../environments/environment'
import {LOGIN_PATH} from '../application/types'

/**
 * We export this so that we can use it in test
 */
export const ADMIN_ROLE_NAME = 'employee'

/**
 * Other parts of the application wants to know this data.
 * Note that we DO NOT send the access token here as it
 * is only needed by the auth interceptor, and it reads it
 * from the Observable.
 */
export interface ILogInState {
  /**
   * Simple yes no for admin if we need it.
   */
  admin: boolean
  /**
   * Name is the only thing we use in this application?
   */
  name: string
  /**
   * Employee ID formed by an 's' and 7 digits
   */
  sId: string
}


@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  /**
   * The access token, primarily needed for the auth interceptor,
   * Note that signal does not work due to that user self is called
   * before the value is propagated.
   */
  public accessToken$ = new BehaviorSubject<string | null>(null)

  /**
   * This is for the "admin", you can add additional
   */
  public isAdmin$ = signal<boolean>(false)

  /**
   * Listen to this when you want to know if the login state has changed.
   */
  public logInState$: WritableSignal<ILogInState | null> = signal(null)

  constructor(
    private ssoService: SingleSignOnService,
    private helperService: HelperService,
    private router: Router
  ) {
  }

  /**
   * This is called from the app module bootstrapper only. So
   * it will happen once and once only.
   */
  public bootstrap(): Observable<boolean> {
    return this.sso()
      .pipe(
        switchMap((value: string | null) => {
          return this.setToken(value)
        })
      )
  }

  /**
   * Called whenever we have token, a token can come from two valid sources
   *
   * 1. From the SSO service
   * 2. From BankID login.
   *
   * We do not care, and we validate and set whatever we get.
   */
  public setToken(token: string | null): Observable<boolean> {
    const payLoad = HelperService.GetTokenPayload(token)
    if (payLoad) {
      this.accessToken$.next(token)
      this.isAdmin$.set(payLoad.roles.includes(ADMIN_ROLE_NAME))
      this.setUserData()
    }
    return of(true)
  }

  /**
   * Call the SSO service, if we get something we return
   * that. Otherwise, nothing. Must be anonymous since we
   * call it from merge/concat
   */
  public sso(): Observable<string> {
    return this.ssoService
      .getToken(environment.authServiceUrl, environment.domain)
      .pipe(
        catchError(() => {
          // We MUST _reset_ if the SSO service says we are logged out!
          // But we do not call the logout again, that is redundant.
          this.reset()
          return EMPTY
        })
      )
  }

  public logout(): void {
    // Blindly just log out from SSO, ignore any errors
    this.ssoService.deleteToken(environment.authServiceUrl).subscribe()
    // Reset all values and navigates to login
    this.reset()
  }

  /**
   * Reset all admin values, and go to login. Do
   * not explicitly log out.
   */
  public reset(): void {
    // This can potentially be a long list of resets...
    this.accessToken$.next(null)
    this.isAdmin$.set(false)
    this.logInState$.set(null)
    // Go to log-in
    this.router.navigate([LOGIN_PATH]).then()
  }

  /**
   * In many cases we also want to fetch the user info we do this
   * totally asynchronous and happily accept that the access token
   * is set properly etc.
   *
   * In Legacy applications we have used this to trigger a reset, which is
   * bad.
   */
  private setUserData(): void {
    this.helperService.getCurrentUser(`${environment.authServiceUrl}`)
      .subscribe({
        next: (user: SparbankenUser) => {
          // Emit login data to subscribers
          this.logInState$.set({
            admin: this.isAdmin$(),
            name: user.name,
            sId: user.sId
          })
        }
      })
  }
}
