import {HttpClientModule} from '@angular/common/http'
import {NgModule} from '@angular/core'
import {MatButtonModule} from '@angular/material/button'
import {MatIconModule} from '@angular/material/icon'
import {MatMenuModule} from '@angular/material/menu'
import {BankIdModule} from '@sparbanken-syd/sparbanken-syd-bankid'
import {ThemeModule} from '@sparbanken-syd/sparbanken-syd-theme'
import {HeaderComponent} from './header/header.component'
import {LoadingButtonComponent} from './loading-button/loading-button.component'
import {LoginComponent} from './login/login.component'

@NgModule({
  declarations: [
    HeaderComponent,
    LoginComponent,
    LoadingButtonComponent
  ],
  imports: [
    HttpClientModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    ThemeModule,
    BankIdModule,
  ],
  exports: [
    HeaderComponent,
    LoginComponent,
    LoadingButtonComponent
  ]
})
export class ComponentsModule {
}
