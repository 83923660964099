import {Injectable} from '@angular/core'
import {MatDialog, MatDialogRef} from '@angular/material/dialog'
import {
  DecoratorDialogComponent,
  TDecoratorDialogStatus
} from './decorator-dialog.component'

@Injectable({
  providedIn: 'root'
})
/**
 * In order to "inject" DecoratorService in decorators,the service needs to be
 * instantiated before the decorator is called.
 * And the way to achieve this is creating an instance of DecoratorService as soon
 * as possible, like in AppModule as APP_INITIALIZER.
 * Also, for testing cases, it will need to be instantiated before the other
 * services/component like "TestBed.inject(DecoratorService)"
 */
export class DecoratorService {
  private static instance: DecoratorService

  private dialogRef: MatDialogRef<DecoratorDialogComponent> | undefined

  constructor(
    private dialog: MatDialog
  ) {
    DecoratorService.instance = this
  }

  /**
   * Opens a dialog or updates its data if one is open already. Unless the opened
   * dialog is a "closable" one. In that case information cannot be changed since
   * it is considered a "user must take action so do not change it" dialog.
   * @param title Dialog's title
   * @param text Dialog's body text
   * @param status Dialog's status. Default value: 'ok'
   * @param closable Dialog's closable flag. Default value: false
   */
  public openDialog(
    title: string,
    text: string,
    status: TDecoratorDialogStatus = 'ok',
    closable: boolean = false
  ) {
    // If it is already open, just change parameters
    if (this.dialogRef) {
      // Do not update data if dialog is waiting for user interaction
      if (!this.dialogRef.componentInstance.isClosable()) {
        this.dialogRef.componentInstance.setData({
          title,
          text,
          status,
          closable
        })
      }
    } else {
      this.dialogRef = this.dialog.open(DecoratorDialogComponent, {
        disableClose: true,
        data: {title, text, status, closable}
      })
    }
  }


  /**
   * Closes the opened dialog unless the dialog is "closable". In that case it
   * is considered as "user must close it manually" dialog.
   * @param waitingTime Time in milliseconds to delay the closing of the dialog
   */
  public closeDialog(waitingTime: number = 0) {
    // Do not close if dialog is waiting for user interaction
    if (this.dialogRef?.componentInstance.isClosable() === true) {
      return
    }

    setTimeout(() => {
      this.dialogRef?.close()
      delete this.dialogRef
    }, waitingTime)
  }

  /**
   * Gets an instance of DecoratorService, which must have been initialised
   * before being used. It works as a fake Angular's "inject()".
   */
  public static getInstance(): DecoratorService {
    return DecoratorService.instance
  }
}
