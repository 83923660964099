/**
 * Store some variables that we want to access here and there
 */

import {
  TCreditPaymentMethod,
  TCreditType
} from '@sparbanken-syd/const-credit-be'

/**
 * The routes are good to be consistent with.
 */
export const LOGIN_PATH = 'login'
export const ADMIN_MODULE = 'admin'
export const ADMIN_CREDITS = 'credits'

/**
 * Route param names used in the routing modules
 */
export const ROUTE_PARAM_CREDIT_ID: string = 'creditId'

export const CreditTypeNames: Map<TCreditType, string> = new Map([
  ['private', 'Privat'],
  ['corporate', 'Företag']
])

export const CreditPaymentMethodNames: Map<TCreditPaymentMethod, string> = new Map([
  ['invoice', 'Mot faktura'],
])


/**
 * Interface to be used as application's administrators. There are more parameters but we are only using a few.
 */
export interface IUser {
  /**
   * Employee personnummer
   */
  sub: string
  /**
   * Employee identifier
   */
  sId: string
  /**
   * First and last name like "Jonas Jonasson"
   */
  name: string
  /**
   * Office name in which the user is registered
   */
  office: string
  /**
   * An array of strings like ["admin", "developer"]
   */
  roles: string[]
}