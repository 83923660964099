<div class="decorator-dialog-holder">
  <span class="title">{{ data.title }}</span>

  @if (data.status === 'loading') {
    <mat-progress-bar class="separator" mode="indeterminate" color="primary"/>
  } @else {
    <div [class]="'separator ' + data.status"></div>
  }

  <span class="text">{{ data.text }}</span>

  @if (data.closable) {
    <div class="actions">
      <button matDialogClose mat-raised-button color="primary">Stäng</button>
    </div>
  }
</div>