import {Component, Inject} from '@angular/core'
import {MatButtonModule} from '@angular/material/button'
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose
} from '@angular/material/dialog'
import {MatProgressBarModule} from '@angular/material/progress-bar'

const TDecoratorDialogStatus = ['loading', 'ok', 'error'] as const
export type TDecoratorDialogStatus = typeof TDecoratorDialogStatus[number]

export interface IDecoratorDialogData {
  /**
   * Dialog's title. Big and informative. Keep it short.
   */
  title: string
  /**
   * Dialog's body text. Some useful description of what is happening/happened
   */
  text: string
  /**
   * Dialog's status, which will decide if the separator color is red ('ko'),
   * yellow ('ok') or instead, a progress bar should be shown ('loading').
   */
  status: TDecoratorDialogStatus
  /**
   * Flag that marks the dialog as 'closable', that will make a button to appear,
   * so that the user can close the dialog manually.
   */
  closable?: boolean
}

@Component({
  templateUrl: './decorator-dialog.component.html',
  styleUrl: './decorator-dialog.component.scss',
  standalone: true,
  imports: [
    MatProgressBarModule,
    MatDialogActions,
    MatButtonModule,
    MatDialogClose
  ]
})
export class DecoratorDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) protected data: IDecoratorDialogData
  ) {
  }

  /**
   * Method used to update data being shown instead of closing and re-opening
   * the dialog
   * @param data Data to show in the dialog
   */
  public setData(data: IDecoratorDialogData) {
    this.data = data
  }

  /**
   * Method to know if the dialog can be manually closed or not.
   */
  public isClosable(): boolean {
    return this.data.closable === true
  }
}