import {Component, EventEmitter, Input, Output} from '@angular/core'

@Component({
  selector: 'spb-loading-button',
  templateUrl: './loading-button.component.html',
  styleUrls: ['./loading-button.component.scss']
})
export class LoadingButtonComponent {
  @Output() onclick = new EventEmitter<Event>()
  @Input({required: true}) isLoading = false
  @Input() text: string = ''
  @Input() disabled: boolean = false
}
